import {Controller} from 'stimulus'
import ApplicationController from "./application_controller";
import alert from "bootstrap/js/src/alert";

export default class extends ApplicationController {
    static values = {
        refresh: Number,
    }

    connect() {
        // window.onload = function(){
        //     $.getScript("https://platform-api.sharethis.com/js/sharethis.js#property=5f02202dadf39b00123aa66d&product=sticky-share-buttons");
        // };
        // let sharedBtnsController = this
        // this.element[this.identifier] = this
        // StimulusReflex.register(sharedBtnsController)
    }

    refreshValueChanged() {
        // if (this.refreshValue === 1) {
        //     this.refreshValue = 0
        // }
    }

    disconnect() {}
}