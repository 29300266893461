import { Controller } from "stimulus"
import '@fancyapps/fancybox/dist/jquery.fancybox.min';

export default class extends Controller {
    static values = {
        refresh: Number,
    }

    connect() {
        // this.initFancyBox()
    }

    refreshValueChanged() {
        if (this.refreshValue === 1) {
            this.refreshValue = 0
            this.initFancyBox()
        }
    }

    initFancyBox(){
        $('[data-fancybox]').fancybox({
            clickOutside: "close",
            thumbs : {
                autoStart   : true,
                hideOnClose : true,
            },
        });
    }

    disconnect() {}
}
