import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import Swal from 'sweetalert2/dist/sweetalert2.all.min'
global.Swal = Swal

export default class extends Controller {
    connect() {
        let sweetalertController = this
        StimulusReflex.register(sweetalertController)
    }

    disconnect() {
    }

    redirect() {
        let sweetalertController = this;
        let $targetElement = $(sweetalertController.data.element);
        Swal.fire({
            customClass: {
                confirmButton: sweetalertController.element.getAttribute('data-custom-confirm-class') ? sweetalertController.element.getAttribute('data-custom-confirm-class') : 'btn btn-outline-info me-2',
                cancelButton: sweetalertController.element.getAttribute('data-custom-cancel-class') ? sweetalertController.element.getAttribute('data-custom-cancel-class') : 'btn btn-outline-danger ms-2',
            },
            buttonsStyling: false,
            title: sweetalertController.element.getAttribute("data-title") ? sweetalertController.element.getAttribute("data-title") : '',
            html: sweetalertController.element.getAttribute('data-html') ? sweetalertController.element.getAttribute('data-html') : "",
            showCancelButton: true,
            confirmButtonText: $targetElement.data('confirm-text') ? $targetElement.data('confirm-text') : 'Yes',
            cancelButtonText: $targetElement.data('cancel-text') ? $targetElement.data('cancel-text') : 'Cancel',
            icon: 'question'
        }).then((result) => {
            if (result.isConfirmed) {
                if($targetElement.data('redirect')){
                    window.open(
                        $targetElement.data('redirect'),
                        '_blank'
                    );
                } else {
                    sweetalertController.stimulate(sweetalertController.data.get("stimulateaction"))
                }
            } else {
            }
        })
    }
}
