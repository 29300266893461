import ApplicationController from './application_controller'
import lozad from 'lozad'

export default class extends ApplicationController {
    static targets = ["modal", "image"]
    static values  = {
        open: Boolean,
    }

    connect() {
        super.connect()
        let filterController = this;
        $(this.modalTarget).on('show.bs.modal', (event) => {
            filterController.show(event);
        })
        $(this.modalTarget).on('hide.bs.modal', (event) => {
            filterController.hide(event);
        })
        if (this.openValue) {
            $(this.modalTarget).modal('show');
        }
    }

    disconnect() {
        $(this.modalTarget).off('show.bs.modal')
        $(this.modalTarget).off('hide.bs.modal')
    }

    show(event) {
        if (window.adsbygoogle !== undefined) {
            for (var i = 0; i < 2; i++) {
                try {
                    (adsbygoogle = window.adsbygoogle || []).push({});
                } catch {
                }
            }
        }
    }

    hide(event) {
    }
}
