import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ["tab", "filter"]

    connect() {
        super.connect()
        let controller = this;
        this.tabTargets.forEach((el, i) => {
            $(el).on('show.bs.collapse', (event) => {
                controller.show(event);
            })
            $(el).on('hide.bs.collapse', (event) => {
                controller.hide(event);
            })
        })
    }

    disconnect() {
        this.tabTargets.forEach((el, _) => {
            $(el).off('show.bs.collapse')
            $(el).off('hide.bs.collapse')
        })
    }

    show(_) {
        this.filterTargets.forEach((el, _) => {
            el.setAttribute('data-filter-visible-value', true);
            el.setAttribute('data-filter-load-value', true);
        })
    }

    hide(_) {
        this.filterTargets.forEach((el, _) => {
            el.setAttribute('data-filter-visible-value', false);
        })
    }
}