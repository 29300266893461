import {Controller} from 'stimulus'
import ApplicationController from "./application_controller";
import 'slick-carousel'

export default class extends ApplicationController {
    connect() {
        $('.slider-nav-before')
            .on('init', function(event, slick) {
                $('.slider-nav-before .slick-slide.slick-current').addClass('is-active');
            })
            .slick({
                slidesToShow: 4,
                slidesToScroll: 4,
                dots: false,
                focusOnSelect: false,
                infinite: false,
                prevArrow: '<div class="slick-prev icon-chevron-right"></div>',
                nextArrow: '<div class="slick-next icon-chevron-right"></div>',
                responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5,
                    }
                }, {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                }, {
                    breakpoint: 420,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                }]
            });


        $('.slider-preview-before').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            infinite: true,
            useTransform: true,
            fade: true,
            swipeToSlide: true,
            touchMove: true,
            swipe: true,
            touchThreshold: 100,
            speed: 100,
            prevArrow: '<div class="slick-prev icon-chevron-right"></div>',
            nextArrow: '<div class="slick-next icon-chevron-right"></div>',
        });


        $('.slider-nav-before').on('click', '.slick-slide', function(event) {
            event.preventDefault();
            var goToSingleSlide = $(this).data('slick-index');

            $('.slider-preview-before').slick('slickGoTo', goToSingleSlide);
        });

        $('.slider-preview-before').on('afterChange', function(event, slick, currentSlide) {
            $('.slider-nav-before').slick('slickGoTo', currentSlide);
            var currrentNavSlideElem = '.slider-nav-before .slick-slide[data-slick-index="' + currentSlide + '"]';
            $('.slider-nav-before .slick-slide.is-active').removeClass('is-active');
            $(currrentNavSlideElem).addClass('is-active');
        });

        $('.slider-nav-after')
            .on('init', function(event, slick) {
                $('.slider-nav-after .slick-slide.slick-current').addClass('is-active');
            })
            .slick({
                slidesToShow: 4,
                slidesToScroll: 4,
                dots: false,
                focusOnSelect: false,
                infinite: false,
                prevArrow: '<div class="slick-prev icon-chevron-right"></div>',
                nextArrow: '<div class="slick-next icon-chevron-right"></div>',
                responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5,
                    }
                }, {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                }, {
                    breakpoint: 420,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                }]
            });


        $('.slider-preview-after').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            infinite: true,
            useTransform: true,
            fade: true,
            swipeToSlide: true,
            touchMove: true,
            swipe: true,
            touchThreshold: 100,
            speed: 100,
            prevArrow: '<div class="slick-prev icon-chevron-right"></div>',
            nextArrow: '<div class="slick-next icon-chevron-right"></div>',
        });


        $('.slider-nav-after').on('click', '.slick-slide', function(event) {
            event.preventDefault();
            var goToSingleSlide = $(this).data('slick-index');

            $('.slider-preview-after').slick('slickGoTo', goToSingleSlide);
        });

        $('.slider-preview-after').on('afterChange', function(event, slick, currentSlide) {
            $('.slider-nav-after').slick('slickGoTo', currentSlide);
            var currrentNavSlideElem = '.slider-nav-after .slick-slide[data-slick-index="' + currentSlide + '"]';
            $('.slider-nav-after .slick-slide.is-active').removeClass('is-active');
            $(currrentNavSlideElem).addClass('is-active');
        });
    }

    disconnect() {}
}