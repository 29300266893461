import {Controller} from 'stimulus'
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["vehicle"]

    connect() {
        super.connect()
        // let filterController = this;
        // $(this.modalTarget).on('show.bs.modal', (event) => {
        //     filterController.show(event);
        // })
        // $(this.modalTarget).on('hide.bs.modal', (event) => {
        //     filterController.hide(event);
        // })
        // if (this.openValue) {
        //     $(this.modalTarget).modal('show');
        // }

    }

    disconnect() {
        // $(this.modalTarget).off('show.bs.modal')
        // $(this.modalTarget).off('hide.bs.modal')
    }

    show(event) {
        event.preventDefault()
        event.stopPropagation()
        let vehicleId = event.currentTarget.getAttribute('data-vehicle-id')
        let zipCode   = event.currentTarget.getAttribute('data-zip-code')
        this.stimulate('VehicleReflex#show', vehicleId, zipCode)
    }
}