import ApplicationController from './application_controller'
import lozad from "lozad";

export default class extends ApplicationController {
    static values = {
        refresh: Number,
    }

    connect() {
        let lozadController = this
        this.element[this.identifier] = this
        StimulusReflex.register(lozadController)
        this.initialize_lozad()
    }

    refreshValueChanged() {
        if (this.refreshValue === 1) {
            this.initialize_lozad()
            this.refreshValue = 0
        }
    }

    initialize_lozad() {
        let observer = lozad();
        observer.observe();
    }
}
