import {Controller} from 'stimulus'
import ApplicationController from "./application_controller";
import alert from "bootstrap/js/src/alert";

export default class extends ApplicationController {
    static values = { quantity: Number }

    connect() {
        super.connect();
        StimulusReflex.register(this)

        if (this.isActionCableConnectionOpen()) {
            if (this.quantityValue === 0) {
                this.stimulate('Vehicle::RecommendedReflex#load', this.element)
            }
        } else {
            if (this.quantityValue === 0) {
                document.addEventListener('stimulus-reflex:connected', () => {
                    this.stimulate('Vehicle::RecommendedReflex#load', this.element)
                })
            }
        }

        $('.recommended-toggle-collapse').on('click', function(e) {
            e.preventDefault();
            $(this).parents('.listing-box').toggleClass('strong-gradient');
            $(this).parents('.listing-box').find('.recommended-body').toggleClass('open');
            $(this).parents('.listing-box').find('.selector').toggle();
            $(this).toggleClass('active');
        });

        $('.recommended-toggle-sticky').on('click', function(e) {
            e.preventDefault();
            $(this).parents('.recommended-block').toggleClass('position-sticky');
            $(this).toggleClass('icon-unpin icon-pin');
        });
    }
}
