import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = [ "input" ]
    static values  = {
        refresh: Number
    }

    connect() {
        super.connect()
    }

    disconnect() {
        super.disconnect()
    }

    refreshValueChanged() {
        if (this.refreshValue === 1) {
            this.initialize_autoc()
            this.refreshValue = 0
        }
    }

    initialize_autoc() {
        let autocController = this;
        var options = {
            getValue: "text",
            list: {
                maxNumberOfElements: 20,
                match: {
                    enabled: true,
                },
                onChooseEvent: function() {
                    autocController.select()
                },

            },
            url: function (phrase) {
                return "/search_autocomplete.json?filter_by%5Bsearch%5D=" + phrase;
            }
        }

        $(this.inputTarget).easyAutocomplete(options);
    }

    select() {
        let value = $(this.inputTarget).getSelectedItemData().text
        document.getElementById('search').setAttribute('data-filter-data-value', value)
    }

    update(event) {
        if (event !== undefined) {
            event.preventDefault();
            event.stopPropagation();
        }
        let value = this.inputTarget.value;
        document.getElementById('search').setAttribute('data-filter-data-value', value)
    }
}