import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static values = {
        refresh: Number,
    }

    connect() {
    }

    refreshValueChanged() {
        if (this.refreshValue === 1) {
            this.init_ads()
            this.refreshValue = 0
        }
    }

    init_ads(){
        for (var i = 0; i < 10; i++) {
            setTimeout(function() {
                try {
                    (adsbygoogle = window.adsbygoogle || []).push({});
                } catch {
                    // oops
                }
            }, 150);
        }
    }
}