// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from "jquery"

require("jquery")
window.jQuery = $;
window.$ = $;

function topFunction() {
    window.scroll({top: 0, behavior: "smooth"})
}

import 'easy-autocomplete/dist/jquery.easy-autocomplete.min'
import '../stylesheets/application'
import 'controllers'
import './mobile_navigations'
import Rails from "@rails/ujs";

window.addEventListener('popstate', function (event) {
    let loc = window.location.pathname
    let mainModalWindow = document.getElementById('main-modal-window')
    console.log(loc)
    switch (true) {
        case loc.startsWith('/vehicle'):
            console.log('vehicle')
            if ($('#modal-window-2').hasClass('show')) {
                console.log('hide modal-window-2')
                $('#modal-window-2').modal('hide')
            }
            if (mainModalWindow === null || mainModalWindow.dataset.vehicleUrl !== loc) {
                Rails.ajax({
                    url: loc,
                    type: 'get',
                    dataType: 'script',
                })
            } else {
                $('#modal-window').modal('show')
            }

            break
        case loc.startsWith('/before_after'):
            let closeButton = document.getElementById('close-before-after-modal')
            let beforeAfterUrlValue = closeButton.dataset.beforeAfterUrl
            console.log('before_after')
            if (beforeAfterUrlValue !== loc) {
                Rails.ajax({
                    url: loc,
                    type: 'get',
                    dataType: 'script',
                })
            } else {
                $('#modal-window-2').modal('show')
            }
            break
        case loc.startsWith('/auction'):
            console.log('auction')
            if ($('#modal-window-2').hasClass('show')) {
                console.log('hide modal-window-2')
                $('#modal-window-2').modal('hide')
            }
            if (mainModalWindow === null || mainModalWindow.dataset.auctionUrl !== loc) {
                Rails.ajax({
                    url: loc,
                    type: 'get',
                    dataType: 'script',
                })
            } else {
                $('#modal-window').modal('show')
            }
            break
        case loc.startsWith('/vin-check'):
            console.log('vin-check')
            if ($('#modal-window-2').hasClass('show')) {
                console.log('hide modal-window-2')
                $('#modal-window-2').modal('hide')
            }
            Rails.ajax({
                url: loc,
                type: 'get',
                dataType: 'script',
            })
            break
        default:
            console.log('default hide modal-window')
            if ($('#modal-window').hasClass('show')) {
                console.log('hide modal-window')
                $('#modal-window').modal('hide')
                let url = document.getElementById('url')
                if (url !== null) {
                    url = url.dataset.urlIndex
                    url = url ? url : '/'
                } else {
                    url = '/'
                }
                window.history.pushState(null, '', url);
            }
            if ($('#listing_block').is(':empty')) {
                document.getElementById('preloader').setAttribute('data-reload-back-vehicles-value', 'true')
            }
    }
})


// on page load replace state

// window.history.replaceState({ path: window.location.href }, '', window.location.href);


// require('@rails/ujs').start()
// import 'bootstrap/dist/js/bootstrap.min.js'
