import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static values = {
        visible: Boolean,
        load: Boolean,
        connected: Boolean,
        checked: Boolean,
        reloadFilter: String,
        partial: String,
        name: String,
        data: String,
        source: String,
    }

    static targets = ["entry"]

    connect() {
        super.connect()
        this.connectedValue = true
    }

    disconnect() {
    }

    visibleValueChanged() {
        // if (this.visibleValue) this.stimulate('FilterReflex#load', this.element)
    }

    dataValueChanged() {
        if (this.dataValue === '') return

        this.stimulate('FilterReflex#update', this.element)
        this.dataValue = ''
    }

    loadValueChanged() {
        if (this.loadValue) this.stimulate('FilterReflex#load', this.element)
        this.loadValue = false
    }

    selectAll(event) {
        let values = [];
        this.entryTargets.forEach((el, i) => {
            el.checked = true;
            values.push(el.getAttribute('data-value'))
        })
        this.stimulate('FilterReflex#select_all', this.nameValue, values)
    }

    deselectAll(event) {
        this.entryTargets.forEach((el, i) => {
            el.checked = false;
        })
        this.stimulate('FilterReflex#select_all', this.nameValue, [])
    }

    update(event) {
        let el      = event.currentTarget
        let tagName = el.tagName
        if (tagName === 'INPUT') {
            this.checkedValue = el.checked
            this.nameValue    = el.name
            if (this.checkedValue) {
                this.dataValue = '_' + el.value // You need to change the value somehow, so that the callback dataValueChanged works
            } else {
                this.dataValue = el.value
            }

        } else if (tagName === 'SELECT') {
            this.nameValue = el.name
            this.dataValue = el.value
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.checkedValue = el.getAttribute('data-checked')
            this.nameValue    = el.getAttribute('data-name')
            if (this.checkedValue) {
                this.dataValue = '_' + el.getAttribute('data-value')
            } else {
                this.dataValue = el.getAttribute('data-value')
            }
        }
    }

    afterUpdate(event) {
        document.getElementById('search_input').value = ''
    }

    sub_update(event) {
        if (event !== undefined) {
            event.preventDefault();
            event.stopPropagation();
        }
        let value = event.target.value
        let name  = event.target.name
        event.target.setAttribute('data-value', value)
        event.target.setAttribute('data-name', name)
        this.stimulate('FilterReflex#sub_update', event.target)
    }
}