import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static values = {
        connected: Boolean,
        side: Boolean,
        tags: Boolean,
        search: Boolean,
        vehicles: Boolean,
        backVehicles: Boolean,
        sort: Boolean,
        filterUrl: String,
    }

    connect() {
        super.connect()
        $('#preloader').fadeOut(30);
        this.connectedValue = true
    }

    disconnect() {
    }

    initAds() {
        if (window.adsbygoogle !== undefined) {
            for (var i = 0; i < 20; i++) {
                try {
                    (adsbygoogle = window.adsbygoogle || []).push({});
                } catch {
                    // oops
                }
            }
        }
    }

    sideValueChanged() {
        if (this.sideValue) this.stimulate('FilterReflex#reload_side', this.element)
        this.sideValue = false
    }

    sortValueChanged() {
        if (this.sortValue) this.stimulate('FilterReflex#reload_sort', this.element)
        this.sortValue = false
    }

    tagsValueChanged() {
        if (this.tagsValue) this.stimulate('FilterReflex#reload_tags', this.element)
        this.tagsValue = false
    }

    searchValueChanged() {
        if (this.searchValue) this.stimulate('FilterReflex#reload_search', this.element)
        this.searchValue = false
    }

    reloadVehicles() {
        if (this.vehiclesValue) {
            window.scroll(0, 0)
            this.stimulate('VehicleReflex#index', this.element)
        }
        this.vehiclesValue = false
    }

    vehiclesValueChanged() {
        if (this.connectedValue) {
            this.reloadVehicles();
        }
    }

    backVehiclesValueChanged() {
        if (this.backVehiclesValue) {
            this.stimulate('VehicleReflex#index', this.element)
        }
        this.backVehiclesValue = false
    }

    beforeIndex(element, reflex, noop, reflexId) {
        if (($("#modal-window").data('bs.modal') || {})._isShown != true) {
            $('#preloader').delay(60).fadeIn(60);
        }
    }

    finalizeIndex(element, reflex, noop, reflexId) {
        $('#preloader').fadeOut(30);
        if (!window.location.pathname.startsWith('/vehicle/')) {
            window.history.replaceState(null, '', this.filterUrlValue)
        }
        this.initAds()
    }
}
