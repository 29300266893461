import {Controller} from 'stimulus'
import ApplicationController from "./application_controller";
import 'slick-carousel'

export default class extends ApplicationController {
    static values = {
        refresh: Number,
    }

    connect() {
    }

    refreshValueChanged() {
        if (this.refreshValue === 1) {
            this.refreshValue = 0
            this.init_slider()
        }
    }

    init_slider() {
        $('.slider-nav-recommended')
            .on('init', function(event, slick) {
                $('.slider-nav-recommended .slick-slide.slick-current').addClass('is-active');
            })
            .slick({
                slidesToShow: 5,
                slidesToScroll: 5,
                dots: false,
                focusOnSelect: false,
                infinite: false,
                prevArrow: '<div class="slick-prev icon-chevron-right"></div>',
                nextArrow: '<div class="slick-next icon-chevron-right"></div>',
                responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                }, {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }, {
                    breakpoint: 420,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }]
            });

        $('.slider-nav-recommended').on('click', '.slick-slide', function(event) {
            event.preventDefault();
            var slickEvent = $(this).find( "img" )
            var url = slickEvent.data('slick-url')
            $('.slider-nav-recommended .slick-slide.is-active').removeClass('is-active');
            $(this).addClass('is-active');
        });
    }

    disconnect() {}
}