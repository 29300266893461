import ApplicationController from './application_controller'
import Rails from '@rails/ujs'
import $ from "jquery";

export default class extends ApplicationController {
    static values = {
        refresh: Number,
        vehicleUrl: String,
    }

    connect() {
        // console.log('PushController connected')
        this.addEventListeners(window, 'hidePrevented.bs.modal', this.handleHidePreventedBsModal)
        this.element[this.identifier] = this
        StimulusReflex.register(this)
    }

    handleHidePreventedBsModal(event) {
        if (event.target.id.includes('modal-window-2')) {
            let closeButton = document.getElementById('close-before-after-modal')
            let vehicleUrlValue = closeButton.dataset.vehicleUrl
            console.log('hidden.bs.modal 2 ', vehicleUrlValue)
            $('#modal-window-2').modal('hide')
            window.history.pushState(null, '', vehicleUrlValue);
            let vehicleModal = document.getElementById('modal-window')
            if (vehicleModal.innerHTML.length === 0) {
                Rails.ajax({
                    url: vehicleUrlValue,
                    type: 'get',
                    dataType: 'script',
                })
            }
        } else if (event.target.id.includes('modal-window')) {
            console.log('hidden.bs.modal 0')
            $('#modal-window').modal('hide')
            let url = document.getElementById('url').dataset.urlIndex
            console.log("URL: ", url)
            url = url ? url : '/'
            window.history.pushState(null, '', url);
            if ($('#listing_block').is(':empty')) {
                document.getElementById('preloader').setAttribute('data-reload-back-vehicles-value', 'true')
            }
        }
    }



    addEventListeners(elem, eventType, handler) {
        if (handler._isEventHandlerSet) {
            return
        }
        console.log('PushController addEventListeners')
        elem.addEventListener(eventType, handler)
        handler._isEventHandlerSet = true
    }

    showVehicle(event) {
        event.preventDefault()
        event.stopPropagation()
        let url = event.currentTarget.href
        window.history.pushState(null, '', url);
        let mainModalWindow = document.getElementById('main-modal-window')
        let loc = url.replace(window.location.origin, '')
        if (mainModalWindow === null || mainModalWindow.dataset.vehicleUrl !== loc) {
            console.log('load vehicle')
            this.loadAjax(url)
        } else {
            $('#modal-window').modal('show');
        }
    }
    
    showBeforeAfter(event) {
        event.preventDefault()
        event.stopPropagation()
        let url = event.currentTarget.href
        window.history.pushState(null, '', url);
        let closeButton = document.getElementById('close-before-after-modal')
        if(closeButton === null || closeButton.dataset.beforeAfterUrl !== url) {
            this.loadAjax(url)
        } else {
            $('#modal-window-2').modal('show')
        }
    }

    showAuction(event) {
        event.preventDefault()
        event.stopPropagation()
        let url = event.currentTarget.href
        window.history.pushState(null, '', url);
        this.loadAjax(url)
    }

    showVinCheck(event) {
        event.preventDefault()
        event.stopPropagation()
        let url = event.currentTarget.href
        window.history.pushState(null, '', url);
        this.loadAjax(url)
    }

    closeVehicle(event) {
        $('#modal-window').modal('hide')
        window.history.pushState(null, '', '/');
        if ($('#listing_block').is(':empty')) {
            document.getElementById('preloader').setAttribute('data-reload-back-vehicles-value', 'true')
        }
    }

    closeBeforeAfter(event) {
        let closeButton = document.getElementById('close-before-after-modal')
        let vehicleUrlValue = closeButton.dataset.vehicleUrl
        console.log('hidden.bs.modal 2 ', vehicleUrlValue)
        $('#modal-window-2').modal('hide')
        window.history.pushState(null, '', vehicleUrlValue);
        let vehicleModal = document.getElementById('modal-window')
        if (vehicleModal.innerHTML.length === 0) {
            Rails.ajax({
                url: vehicleUrlValue,
                type: 'get',
                dataType: 'script',
            })
        }
    }

    loadAjax(url) {
        Rails.ajax({
            url: url,
            type: 'get',
            dataType: 'script',
        })
    }
}
