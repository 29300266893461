import { Controller } from "stimulus"

export default class extends Controller {
    static values  = {
        add: Number,
        remove: Number,
    }

    connect() {
        let metaController = this
        this.element[this.identifier] = this
        StimulusReflex.register(metaController)
    }

    disconnect() {}

    addValueChanged() {
        if (this.addValue === 1) {
            this.addValue = 0
            this.add_no_index_no_follow();
        }
    }

    removeValueChanged() {
        if (this.removeValue === 1) {
            this.removeValue = 0
            this.remove_no_index_no_follow();
        }
    }

    remove_no_index_no_follow() {
        $('meta[name=robots]').remove()
    }

    add_no_index_no_follow() {
        if (window.location.search !== '' && !window.location.search.includes("?page=")) {
            $('head').append('<meta name="robots" content="noindex, nofollow" />')
        }
        else if (window.location.pathname === "/privacy_and_cookie_policy") {
            $('head').append('<meta name="robots" content="noindex, nofollow" />')
        }

        // else if (window.location.pathname.includes("/auction") || window.location.pathname.includes("/vehicle") || window.location.pathname.includes("/before_after")) {
        //     $('head').append('<meta name="robots" content="noindex, nofollow" />')
        // }
    }
}