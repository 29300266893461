import {Controller} from 'stimulus'
import StimulusReflex from "stimulus_reflex";
import swipe from 'jquery-touchswipe'

export default class extends Controller {
    static values = { filterOnLeft: Boolean }

    connect() {
        super.connect()
        StimulusReflex.register(this)
        if (this.filterOnLeftValue == true) {
            LeftBarSwipeLeft();
            LeftBarSwipeRight();
        } else {
            RightBarSwipeLeft();
            RightBarSwipeRight();
        }
        SwipeUp();
        SwipeDown();
    }

    disconnect() {}

    left() {
        LeftBarSwipeLeft();
        LeftBarSwipeRight();
        this.stimulate(this.data.get("stimulateaction"))
    }

    right() {
        RightBarSwipeLeft();
        RightBarSwipeRight();
        this.stimulate(this.data.get("stimulateaction"))
    }
}

function LeftBarSwipeLeft() {
    $('.mobile-overlay').swipe({
        swipeRight:function(event, phase, direction, distance, duration, fingers){},
        swipeLeft:function(event, phase, direction, distance, duration, fingers) {
            if ($('#sidebar-status').hasClass( "overlay" )) {
                $('.mobile-overlay').click();
            }
        }
    });
}

function LeftBarSwipeRight() {
    $('#main-container').swipe({
        swipeLeft:function(event, phase, direction, distance, duration, fingers){},
        swipeRight:function(event, phase, direction, distance, duration, fingers) {
            if (!$('#sidebar-status').hasClass( "overlay" )) {
                $('.mobile-overlay').click();
            }
        }
    });
};

function RightBarSwipeLeft() {
    $('#main-container').swipe({
        swipeRight:function(event, phase, direction, distance, duration, fingers){},
        swipeLeft:function(event, phase, direction, distance, duration, fingers) {
            if (!$('#sidebar-status').hasClass( "overlay" )) {
                $('.mobile-overlay').click();
            }
        }
    });
}

function RightBarSwipeRight() {
    $('.mobile-overlay').swipe({
        swipeLeft:function(event, phase, direction, distance, duration, fingers){},
        swipeRight:function(event, phase, direction, distance, duration, fingers) {
            if ($('#sidebar-status').hasClass( "overlay" )) {
                $('.mobile-overlay').click();
            }
        }
    });
};

function SwipeUp() {
    $('#mobile-tags-block').swipe({
        swipeDown:function(event, phase, direction, distance, duration, fingers) {
            if (!$('#mobile-tags-btn').hasClass( "active" )) {
                $('#mobile-tags-btn').click();
            }
        }
    });
};

function SwipeDown() {
    $('#mobile-tags-block').swipe({
        swipeUp:function(event, phase, direction, distance, duration, fingers) {
            $('#mobile-tags-btn').hasClass( "active" )
            if ($('#mobile-tags-btn').hasClass( "active" )) {
                $('#mobile-tags-btn').click();
            }
        }
    });
};