import {Controller} from 'stimulus'
import StimulusReflex from "stimulus_reflex";
export default class extends Controller {
    connect() {}

    toggle() {
        let tagsBarController = this;
        tagsBarController.element[this.identifier] = this
        StimulusReflex.register(tagsBarController)

        if (!$('#mobile-tags-btn').is('.active') || !$('#desktop-tags-btn').is('.active'))
        {
            $('#mobile-tags-btn').addClass('active');
            $('#desktop-tags-btn').addClass('active');
            $('#filter_tags').addClass('show');
            $('.tag-list').addClass('show');
        }
        else {
            $('#mobile-tags-btn').removeClass('active')
            $('#desktop-tags-btn').removeClass('active')
            $('#filter_tags').removeClass('show');
            $('.tag-list').removeClass('show');
        }

        tagsBarController.stimulate(tagsBarController.data.get("stimulateaction"))
    }

    toggleBanner() {
        let tagsBarController = this;
        tagsBarController.element[this.identifier] = this
        StimulusReflex.register(tagsBarController)
        tagsBarController.stimulate(tagsBarController.data.get("stimulateaction"))
    }
}
