$(document).ready(function() {
    //sidebar close for mobile devises
    $(document).on('click',".btn-aside, .aside-navbar .btn-cls, .btn-side-menu, .blur, .mobile-overlay",function(e){
        e.preventDefault();
        $(this).parents('.viewport-wrapper').toggleClass('overlay');
        $(this).parents('.viewport-wrapper').find('.footer-navbar').toggle();
    });

    //sidebar right for mobile devises
    $(document).on('click',".sideBarBtnRight",function(){
        $(this).addClass('active').siblings().removeClass('active').parents('.viewport-wrapper').addClass('sideBarRight').find('.btn-aside').addClass('right');
    });

    //sidebar left for mobile devises
    $(document).on('click',".sideBarBtnLeft",function(){
        $(this).addClass('active').siblings().removeClass('active').parents('.viewport-wrapper').removeClass('sideBarRight').find('.btn-aside').removeClass('right');
    });

    // scroll up
    $('.btn-up').click(function(){
        $('html, body').animate({scrollTop: 0},500);
        return false;
    });

    // Hide open banners on main page
    $('.catalog-list').on('click',".close",function(e){
        e.preventDefault();
        $(this).parent().parent().hide();
        $(this).parents().find('.btn-show-bnr').show();
    });

    $('.catalog-list').on('click',".btn-show-bnr",function(e){
        e.preventDefault();
        $(this).parents().find('.bnr-item').show();
        $(this).hide();
    });
})
