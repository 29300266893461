// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controller from './application_controller'
import { Autocomplete } from 'stimulus-autocomplete'

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
StimulusReflex.debug = process.env.RAILS_ENV === 'development'
StimulusReflex.initialize(application, { consumer, controller, debug: false })
application.register('autocomplete', Autocomplete)