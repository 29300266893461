import {Controller} from 'stimulus'
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    connect() {
        let recommendedController = this
        this.element[this.identifier] = this
        StimulusReflex.register(recommendedController)
    }


    disconnect() {}

    switchVehicle(event){
        let url = event.currentTarget.dataset.url
        let mainModalWindow = document.getElementById('main-modal-window')
        mainModalWindow.dataset.vehicleUrl = url
        console.log('switchVehicle: ', url)
        window.history.pushState(null, '', url);
        this.stimulate(this.data.get("stimulateaction"))
    }

    afterReflex(element, reflex, noop, reflexId) {
        // window.__sharethis__.initialize()
        $("#url").attr('data-url-new-url', element.dataset.url);
        $("#url").attr('data-url-refresh-value', 1);
    }
}