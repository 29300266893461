import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ["entry", "loader", "append"]

    connect() {
        super.connect()
        this.intersectionObserver = new IntersectionObserver(
            entries => this.processIntersectionEntries(entries),
            {
                rootMargin: this.rootMargin,
                root: document.getElementById(this.rootId),
            }
        )

        document.addEventListener('stimulus-reflex:connected', event => {
            this.observeEntries();
        })
        if (this.isActionCableConnectionOpen()) {
            this.observeEntries()
        }

        this.appendTarget.addEventListener('cable-ready:after-insert-adjacent-html', event => {
            this.observeEntries();
            this.initAds();
        })
    }

    disconnect() {
        this.unobserveEntries();
    }

    observeEntries() {
        this.entryTargets.forEach((el, i) => {
            this.intersectionObserver.observe(el);
        })
    }

    unobserveEntries() {
        this.entryTargets.forEach((el, i) => {
            this.intersectionObserver.unobserve(el);
        })
    }

    initAds() {
        if (window.adsbygoogle !== undefined) {
            for (var i = 0; i < 20; i++) {
                try {
                    (adsbygoogle = window.adsbygoogle || []).push({});
                } catch {
                    // oops
                }
            }
        }
    }

    processIntersectionEntries(entries) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                if (entry.target.nextElementSibling === null) {
                    this.pageIndex = this.pageIndex + 1;
                }
            }
        })
    }

    load() {
        this.pageIndex = this.pageIndex + 1;
    }

    fetchEntries() {
        if (this.nextPage == 1) this.stimulate(this.reflexTarget, this.element)
    }

    showLoader() {
        // console.log(this)
        // if (this.loaderTarget) this.loaderTarget.classList.remove('d-none')
    }

    hideLoader() {
        // console.log(this)
        // if (this.loaderTarget) this.loaderTarget.classList.add('d-none')
    }

    get pageIndex() {
        return parseInt(this.data.get("page"));
    }

    get reflexTarget() {
        return this.data.get("reflex-target");
    }

    get nextPage() {
        return parseInt(this.data.get("next-page"));
    }

    get lastPage() {
        return parseInt(this.data.get("pages"));
    }

    get rootId() {
        return this.data.get("root");
    }

    get rootMargin() {
        return this.data.get("margin")
    }

    set pageIndex(value) {
        if (parseInt(value) > this.lastPage || parseInt(value) < 1) return

        this.data.set("page", value);
        this.unobserveEntries();
        this.fetchEntries();
    }

    beforeReflex() {
        this.showLoader();
    }

    reflexSuccess(element, reflex, noop, reflexId) {
        this.hideLoader()
    }

    reflexError(element, reflex, error, reflexId) {
        this.hideLoader()
    }

    // afterReflex(element, reflex, noop, reflexId) {
    //     this.observeEntries();
    // }
}