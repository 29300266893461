import {Controller} from 'stimulus'
import StimulusReflex from "stimulus_reflex";
import 'select2/dist/js/select2.min.js'
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css'

export default class extends Controller {
    static targets = ['select'];
    static values = {
        refresh: Number
    }

    connect() {
        let selectpickerController = this;
        selectpickerController.element[this.identifier] = this

        StimulusReflex.register(selectpickerController)
        $(selectpickerController.element).on('change', function (e, clickedIndex, isSelected, previousValue) {
            selectpickerController.stimulate(
                selectpickerController.data.get("stimulateaction", { resolveLate: true }),
                selectpickerController.element
            )
        });
    }

    disconnect() {
      $(this.element).off()
    }

    refreshValueChanged() {
        if (this.refreshValue === 1) {
            this.init_selects()
            this.refreshValue = 0
        }
    }

    init_selects() {
        let selectpickerController = this;
        this.element[this.identifier] = this
        $(selectpickerController.element).select2({
            theme: 'bootstrap4',
            ajax: {
                url: function () {
                    return ($(this).data( "url" ).toString());
                },
                data: function (params) {
                  const element = $(`#select2-${this.attr('connectedField')}-container`)
                  let year = null
                  if(element.length > 0){
                    year = element.html()
                  }
                    var query = {
                        search: params.term,
                        page: params.page || 1,
                        current_range: year
                    }
                    return query;
                }
            },
            templateResult: formatRepo,
            templateSelection: formatRepoSelection
        })
        setDefaultValue();

        function setDefaultValue() {
            $(".select2-selection__rendered").each(function() {
                $(this).text($(this).attr('title'))
            });
        }

        function formatRepo (repo) {
            if (repo.loading) {
                return repo.value;
            }

            var $container = $(
                "<div class='select2-result-repository clearfix'>" +
                "<div class='select2-result-repository__value'></div>" +
                "</div>"
            );

            $container.find(".select2-result-repository__value").text(repo.value);
            return $container;
        }

        function formatRepoSelection (repo) {
            return repo.value;
        }
        $('#select2-zip_code-container').text('ZIP: ' + $("#select2-zip_code-container").text())
        $('#select2-mobile-zip-code-container').text('ZIP: ' + $("#select2-mobile-zip-code-container").text())
        $('#select2-radius-container').text('Radius: ' + $("#select2-radius-container").text())
        $('#select2-mobile-radius-code-container').text('Radius: ' + $("#select2-mobile-radius-code-container").text())
    }
}
